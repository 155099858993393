import type { CatalogProductAdNodeResolvers } from '../../__codegen__/__graphql__/resolvers'
import { V1DSPADComponentType } from '../../__codegen__/__openapi__/search-server/integratesearch/v4/integratesearch'
import { CATALOG_PRODUCT_AD_COMPONENT_TYPE } from '../constants'

export const CatalogProductAdNode: CatalogProductAdNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case V1DSPADComponentType.CATALOG_ADVERTISEMENT: {
        return CATALOG_PRODUCT_AD_COMPONENT_TYPE.CATALOG_ADVERTISEMENT
      }
      case V1DSPADComponentType.CAROUSEL_ADVERTISEMENT: {
        return CATALOG_PRODUCT_AD_COMPONENT_TYPE.CAROUSEL_ADVERTISEMENT
      }
      default: {
        return CATALOG_PRODUCT_AD_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
}
